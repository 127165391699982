$signature-green: #3EB871;
$signature-yellow: #FFB81D;
$signature-mango: #FFAF00;
$signature-red: #DE2929;
$signature-white: #ffff;
$signature-black: #000000;
$signature-blue: #00A6FF;
$signature-orange: #F87431;


$signature-main-blue: #2852C4;
$signature-warning-yellow: #ff9800;
$signature-disabled-grey: #bdc3c7;
