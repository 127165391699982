@import '_color.scss';

.pad-b-16 {
    padding-bottom: 16px !important;
}

.pad-b-20 {
    padding-bottom: 20px !important;
}

.pad-b-30 {
    padding-bottom: 30px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-10 {
    padding: 10px !important;
}

.pad-l-24 {
    padding-left: 24px !important;
}

.pad-l-10 {
    padding-left: 10px !important;
}

.pad-t-20 {
    padding-top: 20px !important;
}

.pad-t-6 {
    padding-top: 6px !important;
}

.pad-t-10 {
    padding-top: 10px !important;
}

.pad-r-6 {
    padding-right: 6px !important;
}

.pad-r-25 {
    padding-right: 25px !important;
}

.pad-t-30 {
    padding-top: 30px !important;
}

.mar-t-16 {
    margin-top: 16px !important;
}

.mar-t-30 {
    margin-top: 30px !important;
}


.mar-t-10 {
    margin-top: 10px !important;
}

.mar-t-20 {
    margin-top: 20px !important;
}

.mar-t-50 {
    margin-top: 50px !important;
}

.mar-t-24 {
    margin-top: 24px !important;
}

.mar-b-15 {
  margin-bottom: 15px !important;
}

.mar-b-20 {
    margin-bottom: 20px !important;
}

.mar-b-40 {
    margin-bottom: 40px !important;
}

.mar-r-12 {
    margin-right: 12px !important;
}

.mar-b-10 {
    margin-bottom: 10px !important;
}

.mar-l-10 {
    margin-left: 10px !important;
}

.mar-l-15 {
    margin-left: 15px !important;
}

.mar-l-20 {
    margin-left: 20px !important;
}

.mar-l-5 {
    margin-left: 5px !important;
}

.mar-r-15 {
    margin-right: 15px !important;
}

.mar-r-40 {
    margin-right: 40px !important;
}

.mar-r-10 {
    margin-right: 10px !important;
}
.mar-t-32 {
    margin-top: 32px !important;
}

.dashed-underline {
    cursor: pointer;
    border-bottom: 1.5px dashed $signature-main-blue;
    text-decoration: none;
}

label {
    font-size: 12px;
    color: #A0A9B5 !important;
    margin-bottom: 0.5rem;
}

.mat-datepicker-input {
    outline: 1px solid #ced4da !important;
    border-radius: 0.375rem;
    // border: 1px solid #ced4da;
    padding: 7px;
}

.scroll-bar-unit-reading {
    height: 400px;
    overflow-y: scroll;
}

.scroll-bar-subunit-reading {
    height: 300px;
    width: fit-content;
    overflow-y: auto;
}

.pad-h-20 {
    padding-left: 10px;
    padding-right: 10px;
}

.pad-h-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
