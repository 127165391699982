@import '_color.scss';

.mat-select-disabled {
    cursor: not-allowed;
    background-color: #eaeef1 !important;
}

mat-select.ng-invalid.ng-touched {
    border: none;
    outline: 2px solid $signature-red;
    border-radius: 5px;
}

.mat-datepicker-input.ng-invalid.ng-touched {
  border-radius: 0.375rem;
  border: 2px solid #DE2929;;
  padding: 7px;
}

.mat-paginator-range-label {
    margin: 0 32px 0 24px;
    font-size: 14px;
    font-weight: 500;
}

.mat-paginator-page-size-label {
    margin: 0 4px;
    font-size: 14px;
    font-weight: 500;
}

.mat-select-value-text {
    font-size: 14px;
    font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.do-not-show-radio {
    height: 20px !important;
    width: 20px;
    float: none !important;
    margin: 2px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: flex-start;
  }
  
  .radio-button {
    margin: 5px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $signature-main-blue !important;
  }
  
  
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $signature-main-blue !important;
  }

  .mat-radio-button-checkbox-ripple .mat-radio-button-ripple-element{
    background-color: $signature-main-blue !important;
  }

  .cdk-overlay-pane {
    // margin-top: 35px !important;
    // top: 19px !important;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: $signature-main-blue;
}

.mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-input {
  background-color: $signature-main-blue !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $signature-main-blue !important;
}

.mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
  background-color: $signature-main-blue !important;
}

.alert {
  ul {
    margin-bottom: 0px;
  }
}

snack-bar-container.mat-snack-bar-container {
 margin-top: 38px;
}

snack-bar-container .alert {
  margin-bottom: 0px !important;
  border-radius: 0 !important;
}

.mat-snack-bar-container.fim-snackbar {
  box-shadow: none;
  padding: 0;
  width: 100%;
  max-width: 1012px;
}

.mat-tab-link-container {
  background: white;
}
.mat-tab-links {
  
  
  .mat-tab-link {
    min-width: 100px; 
    padding: 0;
    height: 60px !important;
    opacity: 1;
    overflow: visible;
  }

  .mat-tab-label-active {
    color: #2852C4;
    font-weight: 900;
  }
}
.mat-expansion-panel-header {

  .mat-content-hide-toggle {
    margin-left: 0 !important;
    margin-right: 0;
  }
}

.fuel-closing {
  .card-body {
    padding-top: 0px;
  }
}