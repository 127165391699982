@import '_color.scss';

.green-status {
    height: 12px;
    width: 12px;
    background-color: $signature-green;
    border-radius: 50%;
    display: inline-block;
  }
  
  .yellow-status {
    height: 12px;
    width: 12px;
    background-color: $signature-yellow;
    border-radius: 50%;
    display: inline-block;
  }
  
  .red-status {
    height: 12px;
    width: 12px;
    background-color: $signature-red;
    border-radius: 50%;
    display: inline-block;
  }

  .blue-status {
    height: 12px;
    width: 12px;
    background-color: $signature-blue;
    border-radius: 50%;
    display: inline-block;
  }

  .orange-status {
    height: 12px;
    width: 12px;
    background-color: $signature-orange;
    border-radius: 50%;
    display: inline-block;
  }

  .black-status {
    height: 12px;
    width: 12px;
    background-color: $signature-black;
    border-radius: 50%;
    display: inline-block;
  }
  