.fim-status {
    font-weight: 400;
    border-radius: 5px;
    opacity: 1;
    display: block;
    min-width: 54px;
    text-align: center;
  }

  .fim-status-new {
    background: #FDEDE3 0% 0% no-repeat padding-box;
    color: orange;
    padding: 7px 10px 6px 10px;
  }

  .fim-status-open {
    background: #D8F1E2 0% 0% no-repeat padding-box;
    color: $signature-green;
    padding: 7px 10px 6px 10px;
  }

  .fim-status-red {
    background: #f1bdbd 0% 0% no-repeat padding-box;
    color: $signature-red;
    padding: 7px 10px 6px 10px;
  }

  .gray-background {
    background-color: rgba(0, 0, 0, 0.14) !important;;
  }
    
  .orange-background-selected {
    background-color: rgba(255, 165, 0, 0.45) !important;;
  }
    
  .orange-background {
    background-color: rgba(255, 165, 0, 0.75) !important;;
  }
