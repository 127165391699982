@import '_color.scss';

.fim-accordion-table {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
    .mat-accordion {
        .mat-header-row {
            padding-right: 0;
            color: #333;
            font-size: 13px;
            font-weight: 400;
            min-width: fit-content;
            max-height: 54px;
            border-bottom: 1px solid #E7EBEF;
            padding-bottom: 10px;
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #FFFFFF;
        }
    }
    .mat-expansion-panel {
        overflow: visible !important;
        display: unset;
        &:nth-child(even) .mat-expansion-panel-header {
            background-color: #FFFFFF;
        }
        &:nth-child(odd) .mat-expansion-panel-header {
            background-color: #F9F9F9;
        }
        .mat-content {
            overflow: visible;
        }
        .mat-expansion-panel-header {
            &.mat-row {
                font-size: 6px;
                color: #565F64;
                padding-left: 0;
                padding-right: 0;
                height: fit-content;
                min-width: fit-content;
                cursor: pointer;
                border-bottom: 1px solid #E7EBEF;
            }
            &.mat-row:hover {
                background-color: initial;
            }
        }
        .mat-expansion-panel-content {
            position: unset;
            left: 0;
            font-size: 14px;
            color: #333;
            // background-color: #F4F7F8;
        }
        .mat-expansion-panel-body {
            padding: 0;
            border-bottom: 1px solid #E7EBEF;
        }
        .mat-checkbox-layout {
            margin-bottom: 0;
        }

        .mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
            margin: 0 10px 0 10px;
        }

        .mat-expansion-indicator, .mat-expansion-indicator:after {
            color: $signature-main-blue  !important;
          }

        .mat-expansion-indicator {
            transition: transform .2s ease;
            transform-origin: center center;
            cursor: pointer;


            &::after {
                transform: rotate((-45deg));
                color: #00A6D2;
            }
        }
        &.mat-expanded {
            .mat-expansion-indicator {
                transform: rotate(90deg) !important;
            }
        }
    }

    .mat-expansion-panel-content {
        position: sticky;
        left: 0;
    }
    .mat-row,
    .mat-header-row {
        display: flex;
        // border-bottom-width: 1px;
        border-bottom: 1px solid #E7EBEF;
        border-bottom-style: solid;
        align-items: center;
        box-sizing: border-box;




        &::after {
            display: inline-block;
            min-height: inherit;
            content: '';
        }
    }
    .mat-cell {
        font-size: 14px;
        // font-weight: 300;
        display: flex;
        align-items: center;
    }

    .mat-cell {
        flex: 1;
        word-wrap: break-word;
        // padding-left: 15px;
        padding-right: 0px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .mat-header-cell {
        flex: 1;
        word-wrap: break-word;
        // padding: 0px 15px 15px 15px;
    }

    .mat-header-cell {
        font-size: 15px;
        font-weight: 500;
        color: #333;
    }
}