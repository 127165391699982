@import '_color.scss';

.btn-common {
  color: #fff;
  height: 40px;
  background: $signature-main-blue 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1.0;
  border: none;
  filter: alpha(opacity=100);
  padding-left: 20px;
  padding-right: 20px;
}

.btn-common:disabled {
  color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.btn-sm {
  height: 25px;
  width:40px;
}

// .btn-common:hover {
//   color: #fff;
//   opacity: 0.8;
//   filter: alpha(opacity=80);
// }

.btn-cancel {
  height: 40px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1.0;
  filter: alpha(opacity=100);
  border-color: $signature-main-blue;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-cancel:hover {
  // color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.btn-new {
  color: #fff;
  height: 23px;
  background: #13B713 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /*opacity: 1.0;*/
  /*filter: alpha(opacity=100);*/
  font-size: 9px;
}

.btn-new:hover {
  color: #fff;
  /*opacity: 0.8;*/
  /*filter: alpha(opacity=80);*/
}

.btn.disabled {
  cursor: not-allowed;
}

.calendar_bx{
  position: relative;
}

#input_pad {
  padding: 7px 0px 7px 7px;
}
.mat-datepicker-toggle {
  position:absolute;
  right: 0px;
  top: -2px;
}
