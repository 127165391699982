@import '_color.scss';

.body-inner-container {
    width: 100%;
  }
  
  .w-20 {
    width: 20%;
  }
  
  .w-80 {
    width: 80%;
  }
  .mw-none{
    max-width:none;
  }
  .body-inner-container input, .modal-popup-dialog-container input, .body-inner-container select, .modal-popup-dialog-container select {
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAED;
    border-radius: 4px;
  }
  
  .modal-footer {
    border-top: none !important;
  }
  
  .c-font-9 {
    font-size: 9px;
  }
  
  .c-font-9-b {
    font-size: 9px;
    font-weight: bold;
  }
  .c-font-10 {
    font-size: 10px;
  }
  
  .c-font-10-b {
    font-size: 10px;
    font-weight: bold;
  }
  .c-font-12 {
    font-size: 12px;
  }

  .c-font-12-b {
    font-size: 12px;
    font-weight: bold;
  }
  
  .c-font-14 {
    font-size: 14px;
  }
  
  .c-font-14-b {
    font-size: 14px;
    font-weight: bold;
  }
  
  .c-font-16 {
    font-size: 16px;
  }
  
  .c-font-16-b {
    font-size: 16px;
    font-weight: bold;
  }
  
  .c-font-18 {
    font-size: 18px;
  }
  
  .c-font-18-b {
    font-size: 18px;
    font-weight: bold;
  }
  
  .c-font-20 {
    font-size: 20px;
  }
  
  .c-font-20-b {
    font-size: 20px;
    font-weight: bold;
  }
  
  .c-font-c-0 {
    color: #000 !important;
  }
  
  .c-font-c-1 {
    color: #1B1919 !important;
  }
  
  .c-font-c-2 {
    color: #A0A9B5 !important;
  }
  
  .c-font-c-3 {
    color: #848683 !important;
  }
  
  .c-font-c-4 {
    color: #323331 !important;
  }
  
  .c-font-c-5 {
    color: #418BCA !important;
  }
  
  .c-font-c-6 {
    color: #403D39 !important;
  }
  
  .c-font-c-7 {
    color: #FFF !important;
  }
  
  .c-font-c-8 {
    color: $signature-main-blue !important;
  }
  
  .c-bg-c-0 {
    background-color: #000;
  }
  
  .c-bg-c-1 {
    background-color: #418BCA;
  }
  
  .c-bg-c-2 {
    background-color: $signature-main-blue;
  }
  .c-bg-c-3 {
    background-color: #fff;
  }
  .c-bg-c-4 {
    background-color: #50535A;
  }
  .c-bg-c-5 {
    background-color: #5B5E66;
  }
  .c-bg-c-6 {
    background-color: #e9ecef;
  }
  
  .pos-fixed {
    position: fixed;
  }
  
  
  .pos-absolute {
    position: absolute;
  }
  
  .tab-content {
    background-color: #fff;
    /*border: 1px solid transparent;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: solid 1px #dee2e6;*/
  }
  
  .head-search-by {
    display: block;
    margin-bottom: 9px;
  }
  
  .nav-tabs {
    border-bottom: none;
  }
  
  .card {
    border: none;
    border-radius: 0px !important;
  }
  
  .card-header {
    border-bottom: none;
  }
  
  .grid-card-header {
    /*height: 55px;*/
    margin-top: 10px;
  }
  
  .nav-tabs .nav-link {
    background-color: #E7EAED;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-color: transparent !important;
    margin-bottom: 0px !important;
  }
  
  .nav-tabs .nav-link:not(:first-of-type) {
    margin-left: 3px;
  }
  
  .nav-tabs .active {
    color: #1B1919 !important;
    background-color: #fff;
  }
  
  
  .grid-action-buttons a {
    cursor: pointer;
    height: 28px;
    width: 25px;
    padding-bottom: 4px;
    padding-left: 2px;
  }
  
  .grid-action-buttons a:hover {
    text-decoration: none;
    background-color: #b7e4ff;
  }
  
  .grid-action-buttons svg {
    height: 14px;
    width: 18px;
    color: $signature-main-blue;
  }
  
  .grid-sort-icon svg {
    font-size: 10px;
    color: #848683;
    height: 10px;
    width: 10px;
  }
  
  .ag-header {
    background-color: #fff !important;
    color: #A0A9B5 !important;
    border-bottom-color: #A0A9B5 !important;
  }
  
  .ag-root-wrapper {
    border: none !important;
  }
  
  .ag-row {
    min-height: 80px !important;
    border-bottom-color: #A0A9B5 !important;
    font-size: 14px !important;
    color: #000 !important;
  }
  
  .ag-row div.ag-cell:not(.ag-cell-auto-height) {
    top: 33% !important;
  }
  
  .ag-cell-auto-height {
    top: 0% !important;
  }
  
  .ag-header-cell-text {
    color: #A0A9B5
  }
  
  .grid-active-col-icon {
    color: #13B713;
    opacity: 1;
    height: 20px;
    width: 20px;
    padding-left: 25%;
  }
  
  .grid-in-active-col-icon {
    color: gray;
    padding-left: 25%;
  }
  .modal {
    z-index:1;
  }
  .cdk-overlay-container {
    z-index: 1;
  }
  .modal-backdrop {
    z-index: 0;
  }
  .modal-error-info-success-message {
    height: auto;
    margin-top: 1%;
    max-width: 25%;
    margin-right: 1%;
    left: unset;
  }
  
  .modal-success-message-icon {
    height: 51px;
    width: 57px !important;
  }
  
  .modal-popup-dialog-container {
    /*  flex-direction: row;*/
    width: auto;
  }
  
  .modal-dialog {
    max-width: 700px;
  }
  
  .logo-icon{
    color:#fff;
    opacity:1;
    height:25px !important;
    width:25px !important;
  }
  
  .modal-popup-dialog-container div.modal-header {
    background-color: #1D2A4E;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  
  .modal-popup-dialog-container .modal-footer {
    justify-content: flex-start;
  }
  .alert-triangle-icon{
    margin-bottom:20px;
  }
  .alert-modal-outer-div {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .alert-modal-content-text{
    padding-bottom:20px;
    padding-top:15px;
  }
  .alert-do-not-show-option{
    padding-bottom:20px;
  }
  .alert-do-not-show-option input{
    border: solid 1px #403D39 ;
    border-radius:10px !important;
  }
  .f-border-danger {
    background-color: #ffe8E8 !important;
    border-color: #ff0000 !important;
    padding: 4px !important;
  }
  
  .f-border-normal {
    border-color: #E7EAED !important
  }
  
  .f-err-danger {
    color: #ff0000;
    font-size: 12px;
  }
  
  /*input.ng-invalid.ng-touched {*/
  /*  background-color: #ffe8E8 !important;*/
  /*  border-color: #ff0000 !important;*/
  /*}*/
  
  /*select.ng-invalid.ng-touched {*/
  /*  background-color: #ffe8E8 !important;*/
  /*  border-color: #ff0000 !important;*/
  /*}*/
  
  /*mat-select.ng-invalid.ng-touched {*/
  /*  background-color: #ffe8E8 !important;*/
  /*  border-color: #ff0000 !important;*/
  /*}*/
  
  
  
  .ag-paging-panel {
    justify-content: flex-start;
  }
  
  .display-none {
    display: none !important;
  }
  
  .display-block {
    display: block;
  }
  
  .display-flex {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  
  .float-right {
    float: right;
  }
  
  .justify-c-normal {
    justify-content: normal;
  }
  
  //.vertical-text-align-middle {
  //  vertical-align: -webkit-baseline-middle;
  //}
  
  .switch-active {
    width: 50px !important;
    height: 25px !important;
  }
  
  
  .content-model {
    margin-top: 100px;
  }
  
  
  /*For horizontal row messages*/
  /*.error-model {
    top: 0;
    margin-top:0px;
    max-width: 70%;
  }*/
  
  /*As per new designt.*/
  .error-model {
    margin-top: 1%;
    max-width: 100%;
    margin-right: 1%;
  }
  
  .error-message-content {
    width: 95%;
  }
  
  .error-item:not(:first-of-type) {
    margin-top: 5px;
  }
  
  .error-modal-close-btn {
    height: 25px;
    margin-right: -8px;
    border: none;
    background: transparent;
    font-weight: bold;
  }
  
  .do-not-show-radio {
    height: 20px !important;
    width: 20px;
    float: none !important;
    margin: 2px;
  }
  
  
  .bs-tooltip-right {
    z-index: 99999;
  }
  
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  
  
  .side-loader-icon {
    display: grid;
    justify-content: end;
    margin-top: -25px;
    position: absolute;
    padding-top: 25px;
  }
  
  .required-asterisk {
    color: #ff0000;
    font-weight: bold;
    margin-left: 3px;
    font-size: 1.2em;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .grid-badges {
    display: block;
    min-height: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .badge-with-close {
    width: auto;
    padding: 2px;
    height: 30px;
    background: #EDEFF1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    float: left;
    margin-right: 4px;
    margin-bottom: 2px;
  }
  
  .badge-with-close label {
    height: 13px;
    text-align: left;
    font: normal normal 300 12px/15px Font Awesome 6 Pro;
    letter-spacing: 0px;
    color: #5D5B66;
    opacity: 1;
    padding-left: 5px;
    padding-right: 3px;
    color: #bbb;
    cursor: pointer;
  }
  
  .role-permission-item {
    width: auto;
    float: left;
    padding: 2px;
    margin-right: 4px;
    margin-top: 4px;
  }
  
  
  .accordion-body {
    min-height: 40px;
    width: 640px;
    height: auto;
    max-height: 350px;
    overflow-y: scroll;
  }
  
  .scrollbar-primary::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
  }
  
  .all-permission-box {
    width: 700px;
  }
  
  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
  }
  
  .toggle-button {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
  }
  
  .cdk-overlay-pane{
    margin-top:35px !important;
  }

  .cdk-overlay-pane.mat-datepicker-popup {
    margin-top:0px !important;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: #EDF1F4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }
  .mat-select {
  
    border-radius: 4px;
    opacity: 1;
  }
  .mat-option {
    text-align: left;
    font: normal normal 300 14px/16px HelveticaNeueCyr;
    opacity: 1;
    height: 34px !important;
    margin-top: 2px;
  }
  .mat-option:hover {
    background: #EDF1F4 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
  }
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: $signature-main-blue;
  }
  
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: #8DA7EB;
  }
  
  .mat-select-top-row {
    height: 0px;
    position: sticky;
    top: -3px;
    left: 0px;
    z-index: 99999;
  }
  .mat-select-panel {
    max-height: 400px !important;
    margin-left: 28px;
    margin-top: -6px;
    overflow-x: hidden !important;
    max-width: max-content !important;
    border: 1px solid var(--inactive-tabs-borders);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAED;
    opacity: 1;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background-attachment: fixed !important;
  }
  .mat-pseudo-checkbox {
    border-color: #000;
  }
  .mat-pseudo-checkbox {
    width: 18px !important;
    height: 18px !important;
    border-radius: 10px !important;
    border: solid 1px #000 !important;
  }
  .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: #fff;
  
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
    color:black !important;
  }

  .mat-pseudo-checkbox-checked::after {
    border-width: 1px !important;
  }
  .mat-pseudo-checkbox::after {
    color: #000;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #fff !important;
  }
  
  a.disabled {
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
    color: #ccc;
  }

  .customDisabled {
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
    // color: rgb(116, 113, 113);
    // 131, 131, 131
    background-color: rgb(131, 131, 131);
  }
  
  html, body {
    height: 100%;
  }
  
  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  select.form-control {
    -webkit-appearance: menulist;
  }
  
  .modal-styles {
    padding: 30px;
  }
  .modal-footer {
    padding: 26px;
  }
  
  .realm-3-dots {
    width: 40px;
    opacity: 1;
    text-align: center;
    height: 50px;
    margin-top: -15px;
  }
  
  .realm-3-dots i {
    margin-top: 18px;
  }
  
  .add-users-to-realm-grid {
    height: 350px;
    overflow-y: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7EAED;
    border-radius: 4px;
    opacity: 1;
    padding: 2px;
  }

  .signature-menu {
    .cdk-overlay-pane {
      // top: 19px !important;
      margin-top: 0px !important;
    }
  }
  

  .mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-input {
    background-color: #2852C4;
  }
  
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #2852C4;
  }

  .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
    background-color: #2852C4 !important;
  }

//   .mat-checkbox-frame { 
//     border-color: #2852C4 !important;
// }

.fim-border-bottom {
  border-bottom: 1px solid #d9d5d5
}
