@import '_utilities.scss';
@import '_buttons.scss';
@import '_reusable.scss';
@import '_table.scss';
@import '_overwrite.scss';
@import '_status.scss';
@import '_fim-accordion-table.scss';
@import '_badges.scss';

* {
  font-family: "Helvetica Nue smth", sans-serif;
}

.fim-h2 {
    font-size: 1.625rem;
    color: #333333;
    font-weight: 200;
}

// input {
//     color: #565F64;
// }


input.ng-invalid.ng-touched {
    border: none;
    outline: 2px solid $signature-red;
    border-radius: 5px;
}

input[type="text"]:disabled {
  // border: 1px solid #ced4da;
  outline: 1px solid #ced4da;
}


.fim-error {
    font-size: 14px;
    color: $signature-red;
    padding-top: 6px;
}
.fim-success {
    font-size: 14px;
    color: $signature-green;
    padding-top: 6px;
}

.fim-warning {
    font-size: 14px;
    color: $signature-warning-yellow;
    padding-top: 6px;
}


.card {
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    border: none;
}

.snackBar-success {
    color: $signature-white;
    background-color: $signature-green;
    .mat-simple-snackbar-action {
        color: $signature-white !important;
    }
}

.snackBar-error {
    color: $signature-white;
    background-color: $signature-red;
    .mat-simple-snackbar-action {
        color: $signature-white !important;
    }
}

.dropdown-menu.show {
  margin-top: 10px !important;
  margin-bottom: 10px !important;;
  max-height: 300px !important;
  overflow: auto;
}

.dropdown-item.active, .dropdown-item:active {
  color: #000 !important;
  background-color: rgba(0,0,0,.04) !important;
}

.mat-datepicker-actions {
  display: inline-block !important;
  width: 100% !important;
  text-align: center !important;
}

.ngb-tp button {
  color: #3f51b5 !important;
}

.ngb-tp .btn-outline-primary {
  border-color: #3f51b5 !important;
}

.ngb-tp .btn-outline-primary:hover {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.ngb-tp-input {
  font-size: 0.8rem !important;
}

.opacity-15 {
  opacity: .15 !important;
}

.show-side-bar {
  display: block;
}

.text-right {
  text-align: right;
}

.mat-select-panel {
  margin: 0 !important;
  margin-top: 1rem !important;
}

.mat-option {
  font-size: 0.9rem !important;
}

.ml-3 {
  margin-left: 0.7rem;
}

.custom-status {
  margin-top: 80px;
}

button {
  cursor: pointer;
}


.noGroupAccess {

}


.error-border {
  border: none;
  outline: 2px solid $signature-red;;
  border-radius: 5px;
}

.green-top-border {
  border-top: 3px solid $signature-green;
}

.red-top-border {
  border-top: 3px solid $signature-red;
}

// .mat-table {
//   width: 100%;
//   tbody tr:hover {
//     cursor: pointer;
//     background-color: rgba(237, 241, 245, 0.4);
//   }
//   &.resizing {
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     cursor: col-resize;
//   }
//   .mat-cell {
//     span {
//       display: block;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }
//   }
//   .mat-header-cell {
//     position: relative;
//     &:not(:last-child) {
//       .resize-holder {
//         cursor: col-resize;
//         width: 20px;
//         height: 100%;
//         position: absolute;
//         right: -10px;
//         top: 0;
//         z-index: 1;
//       }
//     }
//   }
//   .mat-cell,
//   .mat-header-cell {
//     &.mat-move-selector {
//       padding: 0 .9rem;
//       border-right: none;
//     }
//     &.isResize {
//       &.mat-move-selector {
//         border-right: 1px solid rgba(222, 222, 222, 0.33) !important;
//       }
//     }
//     &:not(:nth-child(1)) {
//       padding: 0 10px;
//     }
//   }
// }

.table-col-icons {
  text-align: center;
}
.disabled-app {
  pointer-events: none; /* Disable mouse interactions */
  opacity: 0.5; /* Optionally, reduce opacity to indicate the disabled state */
  transition: opacity 0.3s;
}
